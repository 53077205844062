import React from "react"
import Footer from "../components/Footer"
import Layout from "../components/Layout"
import NavBar from "../components/NavBar"
import AboveTheFold from "../components/pages/index/AboveTheFold"
import Achievements from "../components/pages/index/Achievements"
import HowItWorks from "../components/pages/index/HowItWorks"
import IndexMap from "../components/pages/index/IndexMap"
import Proposals from "../components/pages/index/Proposals"
import Testimonials from "../components/pages/index/Testimonials"
import SEO from "../components/Seo"

const Index = () => (
  <Layout>
    <SEO
      title="La Consigne GreenGo"
      description="La Consigne GreenGo offre une solution de consigne pour un monde zéro déchets aux points de vente, la vente en ligne, et les entreprises"
      keywords={["consigne", "emballage", "zéro", "déchets", "restauration"]}
    />
    <NavBar activePage="index" />
    <AboveTheFold></AboveTheFold>
    <HowItWorks></HowItWorks>
    <Testimonials></Testimonials>
    <Achievements></Achievements>
    <Proposals></Proposals>
    <IndexMap />
    <Footer></Footer>
  </Layout>
)

export default Index
