import React from "react"
import bottomShape from "../../../images/index/forme.svg"
import apple from "../../../images/shared/download-appstore.png"
import google from "../../../images/shared/download-googleplay.png"
import "./AboveTheFold.scss"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const AboveTheFold: React.FunctionComponent = () => {

  const images = useStaticQuery(graphql`
    query {
      main2: file(relativePath: { eq: "index/main2.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)  

  return (
    <section className="above-the-fold background--electric-blue padding">
      <div className="above-the-fold__app1">
        <div className="above-the-fold__text">
          <h1 className="heading heading--1">
            Pour un monde sans déchets, suivez la consigne !
          </h1>
          <p className="paragraph paragraph--white paragraph--header">
            Le nouveau service de consigne clé en main et sur mesure pour les
            entreprises et restaurants.
          </p>
          <section className="download">
            <a href="https://itunes.apple.com/fr/app/greengo/id1440472850?mt=8">
              <img src={apple} alt="googleplay"/>
            </a>
            <a href="https://play.google.com/store/apps/details?id=fr.mygreengo.greengo">
              <img src={google} alt="googleplay"/>
            </a>
          </section>
        </div>
        <div className="above-the-fold__img">
          <Img fluid={images.main2.childImageSharp.fluid} alt="greengo-smartphone" />
        </div>
        <div className="above-the-fold_bottom-shape">
          <img src={bottomShape} alt="bottom-shape"/>
        </div>
      </div>
    </section>
  )
}

export default AboveTheFold
