import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Slider, { Settings as SliderSettings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import "./Testimonials.scss"

const Testimonials: React.FunctionComponent = () => {
  const companiesQuery = useStaticQuery(graphql`
    query {
      canalplus: file(
        relativePath: { eq: "index/testimonials/canalplus.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      compass: file(relativePath: { eq: "index/testimonials/compass.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      sg: file(relativePath: { eq: "index/testimonials/sg.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      elior: file(relativePath: { eq: "index/testimonials/elior.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      engie: file(relativePath: { eq: "index/testimonials/engie.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      danone: file(relativePath: { eq: "index/testimonials/danone.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      sodexo: file(relativePath: { eq: "index/testimonials/sodexo.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      creditagricole: file(
        relativePath: { eq: "index/testimonials/credit-agricole.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      idf: file(relativePath: { eq: "index/testimonials/ile-de-france.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      qualiteandco: file(
        relativePath: { eq: "index/testimonials/qualiteandco.png" }
      ) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      sap: file(relativePath: { eq: "index/testimonials/sap.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }

      webedia: file(relativePath: { eq: "index/testimonials/webedia.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const companyImages = [
    // [apiRestauration, "Api restauration"],
    [companiesQuery.canalplus.childImageSharp.fixed, "Canal +"],
    [companiesQuery.compass.childImageSharp.fixed, "Compass"],
    [companiesQuery.creditagricole.childImageSharp.fixed, "Crédit agricole"],
    [companiesQuery.elior.childImageSharp.fixed, "Elior"],
    [companiesQuery.engie.childImageSharp.fixed, "Engie"],
    [companiesQuery.sodexo.childImageSharp.fixed, "Sodexo"],
    [companiesQuery.danone.childImageSharp.fixed, "Danone"],
    // [franprix, "Franprix"],
    [companiesQuery.qualiteandco.childImageSharp.fixed, "Qualité & Co"],
    [companiesQuery.sap.childImageSharp.fixed, "Sap"],
    [companiesQuery.sg.childImageSharp.fixed, "Société Générale"],
    [companiesQuery.webedia.childImageSharp.fixed, "Webedia"],
    [companiesQuery.idf.childImageSharp.fixed, "Région Ile de France"],
  ]

  const settings: SliderSettings = {
    infinite: true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: false,
    accessibility: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: false,
    pauseOnFocus: false,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          infinite: true,
          speed: 500,
          cssEase: "linear",
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          accessibility: true,
          autoplay: true,
          autoplaySpeed: 3000,
          swipe: false,
          pauseOnFocus: false,
          pauseOnHover: false,
        },
      },
    ],
  }

  return (
    <section className="testimonials background--white padding">
      <h2 className="heading heading--2 heading--svg heading--svg-right">
        Nos partenaires suivent la consigne
      </h2>
      <section className="slides">
        <Slider {...settings}>
          {companyImages.map(image => (
            <div>
              <article className="slides_item">
                <div className="img_container">
                  <Img
                    fixed={image[0]}
                    alt={image[1]}
                    className="slider_picture"
                  />
                </div>
              </article>
            </div>
          ))}
        </Slider>
      </section>
    </section>
  )
}

export default Testimonials
