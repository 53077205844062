import * as React from "react"

export const isDesktop = (value: number) => value >= 1024

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = React.useState(99999)

  React.useEffect(() => {
    setWindowSize(window.innerWidth)

    const handleResize = () => {
      setWindowSize(window.innerWidth)
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [setWindowSize])

  return windowSize
}
