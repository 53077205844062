import * as React from "react"
import Map from "../../Map"
import "./IndexMap.scss"

const IndexMap: React.FunctionComponent = () => {
  return (
    <section className="index-map">
      <section className="map-input">
        <div className="top">
          <h2>OÙ TROUVER LA CONSIGNE GREENGO</h2>
          <p className="paragraph paragraph--blue">
            Trouvez un point de collecte ou un partenaire autour de vous !
          </p>
        </div>
        <div className="bottom">
          <div className="hidden-for-the-moment">
            <input
              className="map-input"
              type="text"
              name=""
              id=""
              placeholder="Adresse, métro..."
            />
            <button className="button button--color-pink">Chercher</button>
          </div>
        </div>
      </section>
      <section className="map__elements" id="nous-trouver-map-container">
        <div className="map" id="nous-trouver-map">
          <Map />
        </div>
      </section>
    </section>
  )
}

export default IndexMap
