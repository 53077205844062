import { Link } from "gatsby"
import React from "react"
import { isDesktop, useWindowSize } from "../../../hooks/useWindowSize"
import offre1 from "../../../images/index/offre1.svg"
import offre2 from "../../../images/index/offre2.svg"
import offre3 from "../../../images/index/offre3.svg"
import plus from "../../../images/shared/plus.svg"
import "./Proposals.scss"

const Proposals: React.FunctionComponent = () => {
  const windowSize = useWindowSize()

  return (
    <section className="proposals background--white padding">
      <section className="proposals__description">
        <h2 className="heading heading--2 heading--left heading--svg-left">
          Pour{isDesktop(windowSize) ? <br></br> : null} nos partenaires
        </h2>
        <p className="paragraph--black">
          Que vous soyez{" "}
          <span className="paragraph--bold">
            une entreprise, un distributeur ou une marque,
          </span>{" "}
          La Consigne GreenGo vous propose une offre complète et variée adaptée
          à vos besoins !
        </p>
        {isDesktop(windowSize) ? (
          <Link to="/nos-offres-pro" className="link button button--color-pink">
            Voir nos offres
          </Link>
        ) : null}
      </section>
      <section className="proposals__items">
        <article className="proposals__item">
          <h3 className="heading heading--3">Le kit Greengo</h3>
          <div className="proposals__image proposals__image--kit">
            <img src={offre1} alt="Kit Greengo" />
          </div>
        </article>
        <img src={plus} alt="+" />
        <article className="proposals__item">
          <h3 className="heading heading--3">
            Le logiciel d'exploitation Greengo
          </h3>
          <div className="proposals__image proposals__image--logiciel">
            <img src={offre2} alt="logiciel d'exploitation" />
          </div>
        </article>
        <img src={plus} alt="+" />
        <article className="proposals__item">
          <h3 className="heading heading--3">
            Service opérationnel (optionnel)
          </h3>
          <div className="proposals__image proposals__image--service">
            <img src={offre3} alt="service opérationnel" />
          </div>
        </article>
        {!isDesktop(windowSize) ? (
          <Link to="/nos-offres-pro" className="link button button--color-pink">
            Voir nos offres
          </Link>
        ) : null}
      </section>
    </section>
  )
}

export default Proposals
