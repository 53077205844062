import axios from "axios"
import React from "react"
import { config } from "../../../config"
import collecteur from "../../../images/index/collecteur.svg"
import emballage from "../../../images/index/emballage.svg"
import profil from "../../../images/index/profil.svg"
import "./Achievement.scss"

interface UserStats {
  totalUser: number
}

interface WasteWeight {
  totalGarbage: number
}

interface WasteCount {
  totalContents: number
}

interface Collector {
  boxId: number
}

const defaultProps = {
  center: {
    lat: 48.8259605,
    lng: 2.3513501,
  },
  zoom: 11,
}

const Achievements: React.FunctionComponent = () => {
  const [collectors, setCollectors] = React.useState<Collector[]>([])
  const [wasteWeight, setWasteWeight] = React.useState<WasteWeight>()
  const [wasteCount, setWasteCount] = React.useState<WasteCount>()
  const [users, setUsers] = React.useState<UserStats>()

  async function getData<T>(
    url: string,
    callback: (data: T | undefined) => void
  ) {
    try {
      let response = await axios.get<T>(url)
      callback(response.data)
    } catch (e) {
      callback(undefined)
    }
  }

  React.useEffect(() => {
    getData<Collector[]>(
      `${config.greengoUrl}/box?timestamp=${Date.now()}`,
      collectors => setCollectors(collectors || [])
    )
    getData<WasteWeight>(
      `${config.greengoUrl}/infos/misc?timestamp=${Date.now()}`,
      wasteWeight => setWasteWeight(wasteWeight)
    )
    getData<WasteCount>(
      `${config.greengoUrl}/infos/contents?timestamp=${Date.now()}`,
      wasteCount => setWasteCount(wasteCount)
    )
    getData<UserStats>(
      `${config.greengoUrl}/users/count?timestamp=${Date.now()}`,
      users => setUsers(users)
    )
  }, [])

  return (
    <section className="achievements background--off-white padding">
      <h2 className="heading heading--2 heading--svg-underline">
        Ensemble, Nous avons :
      </h2>
      <ul className="achievements__items">
        <li className="achievements__item achievements__item--collecteur">
          <div>
            <img
              src={collecteur}
              alt="collecteur"
              className="achievements__picture achievements__picture--collecteur"
            />
            <p className="paragraph paragraph--highlight">
              Installé {collectors.length > 0 ? collectors.length : 11}{" "}
              collecteurs
            </p>
          </div>
        </li>
        <li className="achievements__item achievements__item--emballage">
          <div>
            <img
              src={emballage}
              alt="emballage"
              className="achievements__picture achievements__picture--emballage"
            />
            <p className="paragraph paragraph--highlight">
              {/* Évité {((wasteWeight?.totalGarbage ?? 1000) / 1000).toFixed(2)} tonne
              {wasteWeight === undefined || wasteWeight.totalGarbage < 2000 ? "" : "s"}{" "}
              d'emballages */}
              Évité {wasteCount?.totalContents ?? 32400} emballages
            </p>
          </div>
        </li>
        <li className="achievements__item achievements__item--profil">
          <div>
            <img
              src={profil}
              alt="profil"
              className="achievements__picture achievements__picture--profil"
            />
            <p className="paragraph paragraph--highlight">
              Rassemblé {users?.totalUser ?? 3423} utilisateurs
            </p>
          </div>
        </li>
      </ul>
    </section>
  )
}

export default Achievements
