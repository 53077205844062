import { Link } from "gatsby"
import React from "react"
import shop from "../../../images/index/commentcamarche.svg"
import cycle from "../../../images/index/commentcamarche2.svg"
import "./HowItWorks.scss"

const HowItWorks: React.FunctionComponent = () => {
  return (
    <section className="steps background--off-white padding">
      <h2 className="heading heading--2 heading--svg heading--svg-underline">
        Comment ça marche ?
      </h2>
      <section className="steps__items">
        <section className="shop steps__item">
          <div className="shop__image-container">
            <img src={shop} alt="shop" className="shop__image" />
            <p className="shop__paragraph paragraph paragraph--blue">
              <span className="paragraph paragraph--highlight">
                L'enseigne ou e.shop
              </span>
              Je choisis parmi une sélection de produits consignés
            </p>
          </div>
          <h3 className="paragraph paragraph--highlight h3-desktop">
            Offre zéro déchet
          </h3>
        </section>
        <section className="cycle steps__item">
          <div className="cycle__image-container">
            <img
              src={cycle}
              alt="étapes de la consigne"
              className="cycle__image"
            />
            <p className="cycle__paragraph1 paragraph paragraph--blue">
              Je paie mes produits<br></br> + la consigne des emballages
            </p>
            <p className="cycle__paragraph2 paragraph paragraph--blue">
              Je consomme mes produits
            </p>
            <p className="cycle__paragraph3 paragraph paragraph--blue">
              <span className="paragraph paragraph--highlight">
                Point de collecte
              </span>
              Je dépose mes emballages consignés dans le collecteur GreenGo de
              mon choix{" "}
            </p>
            <p className="cycle__paragraph4 paragraph paragraph--blue">
              Je suis crédité du montant de la consigne via l'application mobile
              ou un bon d'achat
            </p>
          </div>
        </section>
        <section className="pricing steps__item">
          <Link to="/contact" className="link button button--color-pink">
            Demander un devis
          </Link>
        </section>
      </section>
    </section>
  )
}

export default HowItWorks
